import React from "react";
import IndividualTotalApplications from "../../Administration/IndividualTotalApplications";

const SuperAdminIndividualApplications = () => {
  return (
    <div>
      <IndividualTotalApplications />
    </div>
  );
};

export default SuperAdminIndividualApplications;
