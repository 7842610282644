import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import Navbar from "../components/navbar/Navbar";

export default function HomepageContainer() {
  document.title = "Nashik Municipal Corporation, Nashik";
  return (
    <>
      <Navbar />
      {/* <Header /> */}
      <div className="min-vh-100">
        <Outlet />
      </div>
    </>
  );
}
