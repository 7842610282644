import React, { useRef } from "react";
import Navbar from "../../components/navbar/Navbar";
import Slider from "../../components/slider/Slider";
import ImageBoxes from "../../components/imgbox/ImageBox";
import IndividualForm from "../../components/form/IndividualForm";
import Form from "../../components/form/Form";
import FocusPage from "../../components/focuspage/FocusPage";
import Footer from "../../components/footer/Footer";
import HomeAbout from "../../components/homeabout/HomeAbout";
import Submission from "../../components/submission/Submission";
import "./home.css";
import FormWithGuidlines from "../../components/form/FormWithGuidlines";
import { useTranslation } from "react-i18next";

const Home = () => {
  return (
    <div>
      <Slider />
      <HomeAbout />
      <FocusPage />
      <FormWithGuidlines />
      {/* <Form /> */}
      <Submission />
      <ImageBoxes />
      <Footer />
    </div>
  );
};

export default Home;
