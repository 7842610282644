import { Col, Row } from "react-bootstrap";
import {
  Button,
  InputAdornment,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  IconButton,
} from "@mui/material";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { useState, useEffect, useContext } from "react";
import { Delete as DeleteIcon } from "@mui/icons-material";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { server } from "../../common";
import { useTranslation } from "react-i18next";
import TermsAndConditions from "../../pages/TermsAndConditions";
import configContext from "../../configContext/configContext";
import Loader from "../../Loader";

const swalalert = withReactContent(Swal);

const initialValues = {
  leader_fullname: "",
  number_of_member: "",
  leader_email: "",
  leader_mobile: "",
  short_idea: "",
  occupation: "",
  upload_proposal: [],
};

const GroupForm = () => {
  document.title = "Group Competition";
  const [processing, setProcessing] = useState(false);
  const { setProgress } = useContext(configContext);
  const { t } = useTranslation();

  const [teamMembers, setTeamMembers] = useState([]);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const {
    values,
    handleBlur,
    touched,
    handleChange,
    handleSubmit,
    errors,
    resetForm,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object({
      leader_fullname: Yup.string()
        .min(2, "Enter a valid Firstname")
        .max(25)
        .required("Please Enter Your Full Name"),
      leader_email: Yup.string()
        .email("email")
        .required("Please Enter Your Email"),
      number_of_member: Yup.number()
        .min(1, "Enter valid number of members")
        .max(5, "Maximum member limit is 5")
        .required("Please Enter Number of Members"),
      occupation: Yup.string()
        .min(1, "Enter valid Details")
        .required("Please Enter Occupation Type"),
      leader_mobile: Yup.string()
        .min(10, "Enter valid Contact Number")
        .max(10, "Enter valid Contact Number")
        .required("Please Enter Your Contact Number"),
      short_idea: Yup.string()
        .min(2, "Enter valid short idea")
        .test("maxWords", "Short idea must not exceed 500 words", (value) => {
          if (!value) return true; // Allow empty values
          const wordCount = value.trim().split(/\s+/).length;
          return wordCount <= 500;
        })
        .required("Please Enter Your short idea"),
      upload_proposal: Yup.mixed().required("Please Upload Proposal"),
    }),
    onSubmit: (values, { resetForm }) => {
      const memberNames = teamMembers
        .map((member) => member.member_name)
        .join(", ");
      const memberEmails = teamMembers
        .map((member) => member.member_email)
        .join(", ");

      const formData = new FormData();
      formData.append("leader_fullname", values.leader_fullname);
      formData.append("number_of_member", values.number_of_member);
      formData.append("leader_email", values.leader_email);
      formData.append("leader_mobile", values.leader_mobile);
      formData.append("member_name", memberNames);
      formData.append("member_email", memberEmails);
      formData.append("short_idea", values.short_idea);
      formData.append("occupation", values.occupation);

      // Convert FileList to Array
      const uploadProposalFiles = Array.from(values.upload_proposal);
      if (uploadProposalFiles.length === 0) {
        toast.error("Please select proposal files");
        return;
      }

      uploadProposalFiles.forEach((file) =>
        formData.append("upload_proposal", file)
      );

      swalalert
        .fire({
          title: "Are You Sure To Submit the Proposal?",
          text: `Confirm?`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
          cancelButtonText: "No",
        })
        .then((swalObject) => {
          if (swalObject.isConfirmed) {
            setProgress(10);
            server
              .post(`/multiple/addmultiple`, formData, {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              })
              .then((response) => {
                if (response.status === 200 || response.status === 201) {
                  swalalert.fire({
                    title: "Success!",
                    text: `Proposal Submitted Successfully. Your Proposal Id Is ${response.data.id}`,
                    icon: "success",
                  });
                  resetForm();
                  setTeamMembers([]);
                  setFieldValue("upload_proposal", []);
                }
              })
              .catch((error) => {
                toast.error(
                  error.response?.data?.message ||
                    error.response?.data?.error ||
                    "Failed to connect to server"
                );
              })
              .finally(() => {
                setProcessing(false); // Stop showing loader
                setProgress(100); // Update progress if required
              });
          } else {
            setProcessing(false); // Stop showing loader if not confirmed
          }
        });
    },
  });

  useEffect(() => {
    const count = Math.min(parseInt(values.number_of_member) || 0, 5); // Limit team members to 5
    setTeamMembers((prev) => {
      if (prev.length !== count) {
        return Array.from({ length: count }, (_, index) => ({
          member_name: prev[index]?.member_name || "",
          member_email: prev[index]?.member_email || "",
        }));
      }
      return prev;
    });
  }, [values.number_of_member]);

  const handleTeamMemberChange = (index, event) => {
    const newMembers = [...teamMembers];
    newMembers[index][event.target.name] = event.target.value;
    setTeamMembers(newMembers);
  };

  // Function to handle member deletion
  const handleDeleteMember = (index) => {
    const newMembers = teamMembers.filter((_, i) => i !== index);
    setTeamMembers(newMembers);
    // Update the number_of_member field after deleting a member
    setFieldValue("number_of_member", newMembers.length.toString());
  };

  return (
    <div>
      <Row>
        <Col md={12}>
          <form onSubmit={handleSubmit}>
            {/* Form fields for leader and members */}
            <Row>
              <Col md={12}>
                <TextField
                  name="leader_fullname"
                  margin="dense"
                  type="text"
                  placeholder={t("Team Leaders Name")}
                  variant="outlined"
                  label={t("Team Leaders Name")}
                  value={values.leader_fullname}
                  onChange={handleChange}
                  fullWidth
                  required
                />
                {errors.leader_fullname && (
                  <p className="text-danger mb-0">{errors.leader_fullname}</p>
                )}
              </Col>
            </Row>

            <Row>
              <Col>
                <TextField
                  name="number_of_member"
                  margin="dense"
                  type="number"
                  placeholder={t("Number Of Members (Max 5)")}
                  variant="outlined"
                  label={t("Number Of Members (Max 5)")}
                  value={values.number_of_member}
                  onChange={handleChange}
                  fullWidth
                  required
                />
                {errors.number_of_member && (
                  <p className="text-danger mb-0">{errors.number_of_member}</p>
                )}
              </Col>
            </Row>
            <Col md={12}>
              <TextField
                name="leader_email"
                margin="dense"
                type="email"
                placeholder={t("Team Leader Email Id")}
                variant="outlined"
                label={t("Team Leader Email Id")}
                value={values.leader_email}
                onChange={handleChange}
                fullWidth
                required
              />
              {errors.leader_email && (
                <p className="text-danger mb-0">{errors.leader_email}</p>
              )}
            </Col>

            <Col md={12}>
              <TextField
                name="leader_mobile"
                margin="dense"
                type="number"
                placeholder={t("Team Leader Contact Number")}
                variant="outlined"
                label={t("Team Leader Contact Number")}
                value={values.leader_mobile}
                onChange={handleChange}
                fullWidth
                required
              />
              {errors.leader_mobile && (
                <p className="text-danger mb-0">{errors.leader_mobile}</p>
              )}
            </Col>
            {/* Team Members Section */}
            <div className="mt-2">
              <h5>{t("Team Members")}</h5>
              {teamMembers.map((member, index) => (
                <Row key={index} className="mb-3">
                  <Col md={5}>
                    <TextField
                      name="member_name"
                      margin="dense"
                      type="text"
                      placeholder={t("Member Name")}
                      label={t("Member Name")}
                      value={member.member_name}
                      onChange={(e) => handleTeamMemberChange(index, e)}
                      fullWidth
                      required
                    />
                  </Col>
                  <Col md={5}>
                    <TextField
                      name="member_email"
                      margin="dense"
                      type="email"
                      placeholder={t("Member Email")}
                      label={t("Member Email")}
                      value={member.member_email}
                      onChange={(e) => handleTeamMemberChange(index, e)}
                      fullWidth
                      required
                    />
                  </Col>
                  {teamMembers.length > 1 && (
                    <Col md={2}>
                      <IconButton
                        onClick={() => handleDeleteMember(index)}
                        className="btn btn-danger btn-sm mt-2"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Col>
                  )}
                </Row>
              ))}
            </div>

            <Col md={12}>
              <FormControl fullWidth className="mt-2 mb-2">
                <InputLabel id="demo-simple-select-label">
                  Occupation
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  label="Occupation"
                  variant="outlined"
                  name="occupation"
                  required
                  value={values.purpose}
                  onChange={handleChange}
                >
                  <MenuItem value="Student">Student</MenuItem>
                  <MenuItem value="Professional">Professional</MenuItem>
                  <MenuItem value="Citizen">Citizen</MenuItem>
                </Select>
              </FormControl>
              {errors.occupation ? (
                <p className="text-danger mb-0">{errors.occupation}</p>
              ) : null}
            </Col>

            {/* Rest of the form */}
            <Row>
              <Col md={12}>
                <TextField
                  name="short_idea"
                  margin="dense"
                  type="text"
                  placeholder={t("Short Description (500 words)")}
                  variant="outlined"
                  label={t("Short Description (500 words)")}
                  value={values.short_idea}
                  onChange={handleChange}
                  fullWidth
                  required
                  multiline
                  rows={4}
                />
                {errors.short_idea && (
                  <p className="text-danger mb-0">{errors.short_idea}</p>
                )}
              </Col>
            </Row>

            <Row>
              <Col md={12}>
                <TextField
                  name="upload_proposal"
                  margin="dense"
                  type="file"
                  variant="outlined"
                  label={t(
                    "Upload Files (you can add upto 5 files) (jpg/png/pdf/PPT or Any File Type, Max 100MB)"
                  )}
                  className="mt-4"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" className="mt-5">
                        <label htmlFor="image"></label>
                      </InputAdornment>
                    ),
                    inputProps: {
                      multiple: true,
                      onChange: (event) => {
                        const files = Array.from(event.currentTarget.files); // Convert FileList to array
                        if (files.length > 5) {
                          toast.error(
                            "You can only upload a maximum of 5 files."
                          );
                          return; // Prevent setting more files if the limit is exceeded
                        }
                        setFieldValue("upload_proposal", files);
                      },
                    },
                  }}
                />
              </Col>
            </Row>

            <Row className="mt-1">
              <div className="text-start mb-2">
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox required />}
                    label={t("I Agree To All the Terms and Conditions")}
                  />
                </FormGroup>

                <Button
                  variant="contained"
                  className="text-center my-1 btn btn-info"
                >
                  <Link
                    onClick={handleShow}
                    className="text-decoration-none text-white"
                  >
                    View Terms and Conditions
                  </Link>
                </Button>
              </div>
              <Col md={12}>
                {/* Loader and Submit Button */}

                <Button
                  variant="contained"
                  type="submit"
                  className="button px-3 text-center my-1 btn btn-info"
                  disabled={processing}
                >
                  {processing ? <Loader /> : t("Submit")}
                </Button>
              </Col>
            </Row>
          </form>
        </Col>
      </Row>

      <TermsAndConditions handleClose={handleClose} show={show} />
    </div>
  );
};

export default GroupForm;
