import React from "react";
import GroupTotalApplications from "../../Administration/GroupTotalApplications";

const SuperAdminGroupApplications = () => {
  return (
    <div>
      <GroupTotalApplications />
    </div>
  );
};

export default SuperAdminGroupApplications;
