import React from "react";
import "./submission.css";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

const Submission = () => {
  const { t } = useTranslation();

  const guidelines = [
    {
      number: "1",
      text: "Any form of presentation, file size (upto 100 MB) and format (PDF/JPG/PNG/PPT/DOC) is acceptable. However we recommend the proposals to be composed on landscape oriented A1 size sheets.",
      color: "orange",
    },
    {
      number: "2",
      text: "Any style of presentation and tool used to convey the idea is acceptable – CAD drawings, GIS, images, 3D model, pictures, sketches etc.",
      color: "teal",
    },
    {
      number: "3",
      text: "A 500-word proposal summary should also be submitted.",
      color: "orange",
    },
    {
      number: "4",
      text: "There is no entry fee for participation in the competition.",
      color: "teal",
    },
    {
      number: "5",
      text: "Stretch is divided into 6 focus areas and participants can opt to submit solutions for one section, part of one section, multiple sections or for the entire stretch from Timbakeshwar to Nashik in a single proposal.",
      color: "orange",
    },
    {
      number: "6",
      text: "Entries would be judged based on elements of creativity, technical excellence, visual impact and how well they communicate the theme.",
      color: "teal",
    },
  ];

  return (
    <div className="container text-center">
      {/* <h4 className="mb-4 text-uppercase">Submission Guidelines</h4>
      <div className="row">
        {guidelines.map((guideline, index) => (
          <div key={index} className="col-md-6 col-lg-4 mb-2">
            <div
              className={`guideline-card ${guideline.color}`}
              style={{ height: "11rem" }}
            >
              <div className="guideline-number">
                <span>{guideline.number}</span>
              </div>
              <div className="guideline-text">{guideline.text}</div>
            </div>
          </div>
        ))}
      </div> */}

      <div className="">
        <h4 className="mt-5">{t("SUBMISSION TIMELINE")}</h4>
        <img
          src="/chart.png"
          className="img-fluid custom-img"
          alt="Submission Timeline"
        />
      </div>

      <h4 className="py-1 text-uppercase">{t("Evaluation Criteria")}</h4>
      <img
        src="/chart2.jpg"
        className="img-fluid custom-img"
        alt="Evaluation Criteria"
      />
    </div>
  );
};

export default Submission;
