import configContext from "./configContext";
import { useState } from "react";

export default function ConfigContextProvider(props) {
    const [progress, setProgress] = useState(0);
    const [file, setFile] = useState(null);
    const [language, setLanguage] = useState(['en', 'mr'].includes(localStorage.getItem('language')) ? localStorage.getItem('language') : 'mr');

    const switchLanguage = () => {
        setLanguage(prev => prev === 'en' ? 'mr' : 'en')
    }
    return (
        <>
            <configContext.Provider value={{ switchLanguage, file, setFile, progress, setProgress, language }}>
                {props.children}
            </configContext.Provider>
        </>
    )
}

