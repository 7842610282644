import { blobToFile } from "../common";
import configContext from "../configContext/configContext";
import { default as React, useContext } from "react";
import { Modal } from "react-bootstrap";

export default function QuickLook() {
  const { file, setFile } = useContext(configContext);

  if (file)
    return (
      <Modal
        show={!!file}
        onHide={() => setFile(null)}
        backdrop={true}
        keyboard
        centered
        size={file.fullScreen ? "xl" : "lg"}
        className="w-100"
        dialogClassName="h-75"
        contentClassName="h-100"
      >
        <Modal.Header closeButton className="py-2">
          <h6 className="text-center w-100">{file.title}</h6>
        </Modal.Header>
        <Modal.Body
          style={{
            overflowY: "hidden",
            paddingTop: 0,
            margin: file.isImage ? "auto" : "unset",
          }}
        >
          {file && (file.isImage || file.data.size) ? (
            <object
              data={
                file.data instanceof Blob ? blobToFile(file.data) : file.data
              }
              type="image/jpg"
              width="100%"
              height="100%"
              style={file.isImage ? { width: "auto", maxWidth: "100%" } : {}}
            />
          ) : (
            <div className="d-flex justify-content-center align-items-center h-100">
              <h5>Cannot Load Document</h5>
            </div>
          )}
        </Modal.Body>
      </Modal>
    );
}
