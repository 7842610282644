import React, { useState } from "react";
import "./focus.css";
import useQuickLook from "../../quicklook/useQuickLook";
import { useTranslation } from "react-i18next";

const FocusPage = () => {
  const displayFile = useQuickLook();
  const { t } = useTranslation();

  const places = [
    {
      img: "/a (2).jpeg",
      name: t("Kushavart Tirth - Ahilya Goda Sangam"),
      location: "https://maps.app.goo.gl/v1qEsQtjWPuZNxRn7",
      hoverImg: "/kushavart.PNG",
    },
    {
      img: "shree chandr ghat 4.jpg",
      name: t("Shri Chandra Ghat - Trimbakeshwar"),
      location: "https://maps.app.goo.gl/Lnm54octEhRLpau69",
      hoverImg: "/chandramap.jpg",
    },
    {
      img: "someshwar.jpeg",
      name: t("Someshwar Waterfall & Temple - Nashik"),
      location: "https://maps.app.goo.gl/ApjwM6pgfD2ubtDV6",
      hoverImg: "/someshwarmap.jpg",
    },
    {
      img: "ganga.jpeg",
      name: t("Ahilyadevi Holkar Bridge to Talkuteshwar Temple - Nashik"),
      location: "https://maps.app.goo.gl/d5bUqwZGh2WcvbCs9",
      hoverImg: "/holkarmap.jpg",
    },
    {
      img: "a (5).jpeg",
      name: t("Tapovan - Nashik"),
      location: "https://maps.app.goo.gl/ASmeUW5xQ315wpi6A",
      hoverImg: "/tapovanap.jpg",
    },
    {
      img: "ahilya ghat 5.jpg",
      name: t("Dasak Ghat Area - Nashik"),
      location: "https://maps.app.goo.gl/YsupW2wns1dbdkHG8",
      hoverImg: "/dasakmap.jpg",
    },
  ];

  // Handle hover state for each card
  const [hoverIndex, setHoverIndex] = useState(null);

  return (
    <div className="container text-center">
      <h4 className="text-uppercase py-2">{t("Focus Areas")}</h4>
      <div className="row mt-4">
        {places.map((item, index) => (
          <div className="col-md-6 col-lg-4 mb-4" key={index}>
            <div
              className="card card-hover h-100 shadow border-0"
              onMouseEnter={() => setHoverIndex(index)} // Set hover index
              onMouseLeave={() => setHoverIndex(null)} // Reset hover index
            >
              <div className="img-wrapper">
                <img
                  src={hoverIndex === index ? item.hoverImg : item.img} // Swap image on hover
                  className="card-img-top rounded"
                  alt={item.name}
                  style={{ height: "250px", objectFit: "cover" }}
                  onClick={() =>
                    displayFile(item.name, item.img, {
                      isImage: true,
                    })
                  }
                />
                <div className="title-wrapper">
                  <h6 className="title-text">{item.name} </h6>
                  <a
                    className="text-white title-text"
                    href={item.location}
                    target="_blank"
                  >
                    View Location
                  </a>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FocusPage;
