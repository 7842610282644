import {
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { Modal, Row, Col } from "react-bootstrap";
import { AiFillDelete, AiFillEdit } from "react-icons/ai";

import { server } from "../common";
import { AxiosError } from "axios";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useSelectAccess } from "../store/stateFunctions";
import useQuickLook from "../quicklook/useQuickLook";

const initialValues = {
  title: "",
  image: null,
};

const swalalert = withReactContent(Swal);

const AddSlider = () => {
  const user = useSelectAccess("Super Admin");
  const displayFile = useQuickLook();

  const [show, setShow] = useState(false);
  const [data, setData] = useState([]);
  const [selectedData, setSelectedData] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setIsEditMode(false);
    setSelectedData(null);
    formik.setValues(initialValues);
    setShow(true);
  };

  const handleShowEdit = (data) => {
    setSelectedData(data);
    setIsEditMode(true);
    setShow(true);
  };

  useEffect(() => {
    getData();
    if (isEditMode && selectedData) {
      formik.setValues({
        title: selectedData.title,
        image: selectedData.image,
      });
    }
  }, [isEditMode, selectedData]);

  // Get
  const getData = () => {
    server
      .get("/slider/getallslider", {
        headers: {
          "Content-Type": "application/json",
          // "auth-token": user.authToken,
        },
      })
      .then(function (response) {
        console.log("api response", response.data);
        if (response.status === 200 || response.status === 201) {
          setData(response.data);
        }
      })
      .catch(function (error) {
        if (error instanceof AxiosError && error.response?.data?.message)
          toast.error(error.response.data.message);
        else if (error.response?.data?.error) {
          toast.error(error.response.data.error);
        } else toast.error("Failed to connect to server");
      });
  };

  // Delete
  const deleteSlider = (data) => {
    swalalert
      .fire({
        title: "Delete Confirmation!",
        text: `Are You Sure That You Want To Delete ${data.link} ?`,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        confirmButtonClass: "btn btn-success",
        cancelButtonClass: "btn btn-danger",
        buttonsStyling: true,
      })
      .then(function (swalObject) {
        if (swalObject.isConfirmed) {
          server
            .delete(`/slider/deleteslider/${data.id}`, {
              headers: {
                "Content-Type": "application/json",
                Authorization: user.authToken,
              },
            })
            .then(function (response) {
              console.log("api response", response.data);
              if (response.status === 200 || response.status === 201) {
                swalalert.fire(
                  "Deleted!",
                  "Internship has been deleted.",
                  "success"
                );
                getData();
              }
            })
            .catch((error) => {
              toast.error(error.response.data.message);
            });
        } else {
          swalalert.fire(
            "Cancelled",
            "Your imaginary file is safe :)",
            "error"
          );
        }
        //success method
      });
  };

  // update
  const handleUpdate = (values) => {
    const formData = new FormData();
    formData.append("title", values.title);
    if (values.image) {
      formData.append("image", values.image);
    }

    server
      .put(`/slider/updateslider/${values.id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: user.authToken,
        },
      })
      .then(function (response) {
        formik.resetForm();
        getData();
        toast.success("Talks Updated successfully");
        handleClose();
      })
      .catch(function (error) {
        if (error.response?.data?.message)
          toast.error(error.response.data.message);
        else if (error.response?.data?.error) {
          toast.error(error.response.data.error);
        } else toast.error("Failed to connect to server");
      });
  };

  //add
  const formik = useFormik({
    initialValues: isEditMode ? selectedData : initialValues,
    validationSchema: Yup.object({
      title: Yup.string().required("Enter a Slider title"),
      image: Yup.mixed()
        .required("Please select an image")
        .test("fileSize", "File size is too large", (value) => {
          // Check the file size (if required)
          // Modify the limit according to your requirements
          return value ? value.size <= 5000000 : true;
        }),
    }),
    onSubmit: (values, action) => {
      if (isEditMode) {
        handleUpdate({ ...values, id: selectedData.id });
      } else {
        if (!values.image) {
          toast.error("Please select a images");
          return;
        }
        const formData = new FormData();
        formData.append("title", values.title);
        formData.append("image", values.image);

        server
          .post("/slider/addslider", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: user.authToken,
            },
          })
          .then(function (response) {
            if (response.status === 200 || response.status === 201) {
              toast.success("Slider Added successfully");
              formik.resetForm();
              handleClose();
              getData();
            }
          })
          .catch(function (error) {
            if (error.response?.data?.message)
              toast.error(error.response.data.message);
            else if (error.response?.data?.error) {
              toast.error(error.response.data.error);
            } else toast.error("Failed to connect to server");
          });
      }
    },
  });

  // search filter wise
  const FilterData = (event) => {
    const selectedValue = event.target.value;
    server
      .get(`/slider/admfilter?filterByDate==${selectedValue}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: user.authToken,
        },
      })
      .then(function (response) {
        console.log("api response", response.data);
        if (response.status === 200 || response.status === 201) {
          setData(response.data);
        }
      })
      .catch(function (error) {
        if (error instanceof AxiosError && error.response?.data?.message)
          toast.error(error.response.data.message);
        else if (error.response?.data?.error) {
          toast.error(error.response.data.error);
        } else toast.error("Failed to connect to server");
      });
  };

  return (
    <div>
      <div className="container">
        <div className="d-flex position-relative mb-3 justify-content-center ">
          <h5 className="m-auto text-center">Slider Images</h5>
          <Button variant="contained" color="info" onClick={handleShow}>
            Add Slider
          </Button>

          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>
                {isEditMode ? "Edit Slider Images" : "Add Slider Images"}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <form onSubmit={formik.handleSubmit}>
                <div className="form-outline mb-2">
                  <TextField
                    name="title"
                    margin="dense"
                    type="text"
                    placeholder="Title"
                    variant="outlined"
                    label="Slider Title"
                    value={formik.values.title}
                    onChange={formik.handleChange}
                    fullWidth
                    required
                  ></TextField>
                  {formik.errors.title ? (
                    <p className="text-danger">{formik.errors.title}</p>
                  ) : null}
                </div>

                <div className="form-outline mb-2">
                  <TextField
                    name="image"
                    margin="dense"
                    type="file"
                    variant="outlined"
                    label="Slider Images"
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <label htmlFor="image">Upload Image</label>
                        </InputAdornment>
                      ),
                      inputProps: {
                        accept: ".jpg, .png, .jpeg",
                        onChange: (event) => {
                          formik.setFieldValue(
                            "image",
                            event.currentTarget.files[0]
                          );
                        },
                      },
                    }}
                  />
                  {formik.errors.image ? (
                    <p className="text-danger">{formik.errors.image}</p>
                  ) : null}
                </div>

                <div className="pt-1 mb-2 ">
                  <Button variant="contained" type="submit">
                    {isEditMode ? "Update" : "Post"}
                  </Button>
                </div>
              </form>
            </Modal.Body>
          </Modal>
        </div>
        <div className="container" style={{ overflow: "scroll" }}>
          <table class="table table-striped border">
            <thead>
              <tr>
                <th scope="col">Sr. No.</th>
                <th scope="col">Title</th>
                <th scope="col">Images</th>
                <th scope="col">Date</th>
                <th scope="col">Update</th>
                <th scope="col">Delete</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => {
                return (
                  <tr key={item.id}>
                    <th scope="row">{index + 1}</th>
                    <td>{item.title}</td>
                    <td>
                      <a href={item.image} target="_blank">
                        <img
                          width="50"
                          height="50"
                          src={item.image}
                          // alt={item.file}
                        />
                      </a>
                    </td>
                    <td>{item.created_at}</td>
                    <td>
                      <div className="btn btn-info text-white">
                        <AiFillEdit onClick={() => handleShowEdit(item)} />
                      </div>
                    </td>
                    <td>
                      <div className="btn btn-danger text-white">
                        <AiFillDelete onClick={() => deleteSlider(item)} />
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>{" "}
        </div>
      </div>
    </div>
  );
};

export default AddSlider;
