import superAdminReducer from "./superAdminReducers.js";
import diviOfficeUserReducer from "./diviOfficeUserReducer.js";


const storeReducer = {
  superadmin: superAdminReducer,
  divisionofficer: diviOfficeUserReducer,

};

export default storeReducer;
