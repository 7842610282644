import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle";
import { HashRouter } from "react-router-dom"
import "react-toastify/dist/ReactToastify.css";
import { Provider } from 'react-redux';
import store from './store';
import ConfigContextProvider from './configContext/ConfigContextProvider';
// translate languages
import { I18nextProvider } from "react-i18next";
import i18n from "i18next";
import "./i18n"

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <HashRouter>
    <Provider store={store}>
      <ConfigContextProvider>
        <I18nextProvider i18n={i18n}>
          <App />
        </I18nextProvider>
      </ConfigContextProvider>
    </Provider>
  </HashRouter>
);
