import React from 'react'

const Languagedropdown = (props) => {
    return (
        <>
            <select onChange={props.onChange} className='form-control'>
                <option selected>Select Language</option>
                <option value={"en"}>English</option>
                <option value={"mr"}>Marathi</option>
            </select>
        </>
    )
}

export default Languagedropdown