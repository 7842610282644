import {
  AiOutlineDashboard,
  AiOutlineUser,
  AiOutlinePaperClip,
  AiFillHdd,
} from "react-icons/ai";
import { FaBusinessTime, FaBlogger, FaPhotoVideo } from "react-icons/fa";
import { BiMenu, BiPodcast, BiSolidBusiness } from "react-icons/bi";
// import { Outlet } from "react-router-dom";
import { IoIosContact } from "react-icons/io";
import { FaBoltLightning } from "react-icons/fa6";
import { Layout, Menu } from "antd";
import { logoutAccess } from "../../store/stateFunctions";
import { Outlet, useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { GrUserPolice } from "react-icons/gr";
import { FaTrafficLight } from "react-icons/fa";
import { FaUserShield } from "react-icons/fa";
const { Sider, Content } = Layout;

const SuperAdminSidebar = ({ role }) => {
  // const {
  //   token: { colorBgContainer },
  // } = theme.useToken();
  const navigate = useNavigate();

  return (
    <div>
      <div className="row p-3 backgroundcolor">
        <div className="col-md-2">
          <a
            className="bg-white p-2 rounded-3 border border-bottom border-2 border-dark shadow"
            data-bs-toggle="offcanvas"
            href="#offcanvasExample"
            role="button"
            aria-controls="offcanvasExample"
            id="side"
          >
            <BiMenu style={{ color: "black", fontSize: 25 }} />
          </a>
        </div>
        <div className="col-md-8 text-center text-white">
          <h4>Welcome To The Nashik Municipal Corporation, Nashik</h4>
        </div>
        <div className="col-md-2 text-end">
          <Button
            variant="contained"
            color="error"
            className=" fw-bold"
            onClick={() => logoutAccess("Super Admin")}
          >
            Logout
          </Button>
        </div>
      </div>
      <ul className="nav">
        <li className="nav-item" id="sidebarm">
          <div
            className="offcanvas offcanvas-start"
            style={{ width: "24rem" }}
            tabIndex={-1}
            id="offcanvasExample"
            aria-labelledby="offcanvasExampleLabel"
          >
            <div className="offcanvas-header backgroundcolor text-white ">
              <p id="size" className="fs-4">
                Super Admin Panel
              </p>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              />
            </div>
            <div className="offcanvas-body ">
              <Sider width={350} style={{ height: "100vh" }}>
                <div style={{ height: "100%" }}>
                  <Menu
                    theme="light"
                    mode="inline"
                    defaultSelectedKeys={["dashboard"]}
                    onClick={({ key }) => {
                      if (key === "signout") {
                      } else {
                        navigate(key);
                      }
                    }}
                    items={[
                      // {
                      //   key: "dashboard",
                      //   icon: <AiOutlineDashboard className="fs-4" />,
                      //   label: "Dashboard",
                      // },

                      // {
                      //   key: "Applications",
                      //   icon: <FaBusinessTime className="fs-4" />,
                      //   label: "Total Applications",
                      //   children: [
                      //     {
                      //       key: "individualapplications",
                      //       icon: <AiFillHdd className="fs-4" />,
                      //       label: "Individual Applications",
                      //     },
                      //     {
                      //       key: "Groupapplications",
                      //       icon: <AiFillHdd className="fs-4" />,
                      //       label: "Group Applications",
                      //     },
                      //   ],
                      // },
                      {
                        key: "individualapplications",
                        icon: <AiFillHdd className="fs-4" />,
                        label: "Total Applications",
                      },
                      // {
                      //   key: "Groupapplications",
                      //   icon: <AiFillHdd className="fs-4" />,
                      //   label: "Total Applications of Group Applicants",
                      // },
                      {
                        key: "addfiles",
                        icon: <AiFillHdd className="fs-4" />,
                        label: "Add Files",
                      },
                      // {
                      //   key: "addslider",
                      //   icon: <AiFillHdd className="fs-4" />,
                      //   label: "Add Slider",
                      // },
                    ]}
                  />
                </div>
              </Sider>
            </div>
          </div>
        </li>
      </ul>

      <Content
        style={{
          // margin: "24px 16px",
          padding: 22,
          // minHeight: 280,
        }}
      >
        {/* <Outlet /> */}
      </Content>
    </div>
  );
};

export default SuperAdminSidebar;
