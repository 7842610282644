import React from "react";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const availableLanguages = ["en", "mr"];

i18n
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                translation: require("./languages/english/translation.json"),
            },
            mr: {
                translation: require("./languages/marathi/translation.json"),
            },
        },
        lng: "en",
        fallbackLng: "en",
        whitelist: availableLanguages,
    });

export default i18n