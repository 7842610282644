import React, { useState } from 'react'

import { ToastContainer } from "react-toastify";
import Home from "./pages/home/Home"
import { Route, Routes } from 'react-router-dom';
import TermsAndConditions from './pages/TermsAndConditions';
import Contact from './pages/contact/Contact';
import DownLoad from './pages/Download';
import About from "./pages/About/About";
import Form from './components/form/Form';
import HomepageContainer from './containers/HomeContainer';
import SuperAdminContainer from './containers/SuperAdminContainer';
import SuperadminLogin from './pages/superadmin/SuperadminLogin';
import SuperAdminDashboard from './pages/superadmin/SuperAdminDashboard';
import SuperAdminIndividualApplications from './pages/superadmin/SuperAdminIndividualApplications';
import SuperAdminGroupApplications from './pages/superadmin/SuperAdminGroupApplications';
import SuperAdminAddFiles from './pages/superadmin/SuperAdminAddFiles';
import QuickLook from './quicklook/QuickLook';
import SuperAdminAddSlider from './pages/superadmin/SuperAdminAddSlider';
import LoadingBar from "react-top-loading-bar";
import Loader from './Loader';

const App = () => {

  return (
    <div>
      <ToastContainer toastClassName="toast-homepage" />
      {/* <LoadingBar
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
        height="3px"
      /> */}
      <QuickLook />
      <Routes>

        <Route path="/" element={<HomepageContainer />}>
          <Route index path="/" element={<Home />} />
          <Route exact path="termandconditions" element={<TermsAndConditions />} />
          <Route path="contact" element={<Contact />} />
          <Route path="download" element={<DownLoad />} />
          <Route path="about" element={<About />} />
          <Route path="form" element={<Form />} />
        </Route>

        <Route path="/super-admin" element={<SuperadminLogin />} />
        <Route path="super-admin" element={<SuperAdminContainer />}>
          <Route path="dashboard" element={<SuperAdminDashboard />} />
          <Route path="individualapplications" element={<SuperAdminIndividualApplications />} />
          <Route path="groupapplications" element={<SuperAdminGroupApplications />} />
          <Route path="addfiles" element={<SuperAdminAddFiles />} />
          <Route path="addslider" element={<SuperAdminAddSlider />} />
        </Route>

      </Routes>
    </div>
  );
}

export default App
