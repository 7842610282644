import React from "react";
import AddFiles from "../../Administration/AddFiles";

const SuperAdminAddFiles = () => {
  return (
    <div>
      <AddFiles />
    </div>
  );
};

export default SuperAdminAddFiles;
