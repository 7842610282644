import axios from "axios";
import { BACKEND_SERVER_URL, superAdminTokenName, DiviOfficeUserTokenName } from "./config";

export const server = axios.create({
  baseURL: BACKEND_SERVER_URL,
  proxy: BACKEND_SERVER_URL,
});


export const removeSuperAdminToken = () => {
  return sessionStorage.removeItem(superAdminTokenName);
};


export const removeDiviOfficeUserToken = () => {
  return sessionStorage.removeItem(DiviOfficeUserTokenName);
};




export const truncateText = (text, limit) => {
  const words = text.split(" ");
  if (words.length > limit) {
    return words.slice(0, limit).join(" ") + " ...";
  }
  return text;
};

export const blobToFile = (data) => {
  return data ? window.URL.createObjectURL(data) : null;
};
