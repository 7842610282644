import configContext from "../configContext/configContext";
import { useContext } from "react";

export default function useQuickLook() {
  const { setFile } = useContext(configContext);
  return (
    title,
    data,
    { fullScreen = true, isImage = false } = {
      fullScreen: true,
      isImage: false,
    }
  ) => setFile({ title, data, fullScreen, isImage });
}
