// import React from "react";
// import {
//   FaFacebook,
//   FaTwitter,
//   FaInstagram,
//   FaLinkedin,
//   FaYoutube,
// } from "react-icons/fa";
// import "./contact.css";
// import { Timeline, Tweet } from "react-twitter-widgets";

// const Contact = () => {
//   return (
//     <div className="container my-5">
//       <div className="row text-center">
//         {/* Office */}
//         <div className="col-lg-4 col-md-6 mb-4">
//           <div className="card contact border-0 shadow">
//             <div className="card-body">
//               <i className="bi bi-geo-alt-fill text-primary fs-2"></i>
//               <h5 className="card-title mt-3">OUR MAIN OFFICE</h5>
//               <p className="card-text">
//                 Nashik Municipal Corporation Rajiv Gandhi Bhavan,
//                 <br />
//                 Sharanpur Road, Nashik
//               </p>
//             </div>
//           </div>
//         </div>
//         {/* Phone */}
//         <div className="col-lg-4 col-md-6 mb-4">
//           <div className="card contact border-0 shadow">
//             <div className="card-body">
//               <i className="bi bi-telephone-fill text-primary fs-2"></i>
//               <h5 className="card-title mt-3">PHONE NUMBER</h5>
//               <p className="card-text">
//                 +91-7030300300
//                 <br />
//               </p>
//             </div>
//           </div>
//         </div>

//         {/* Email */}
//         <div className="col-lg-4 col-md-6 mb-4">
//           <div className="card contact border-0 shadow">
//             <div className="card-body">
//               <i className="bi bi-envelope-fill text-primary fs-2"></i>
//               <h5 className="card-title mt-3">EMAIL</h5>
//               <p className="card-text">godavarinmc@gmail.com</p>
//             </div>
//           </div>
//         </div>
//       </div>
//       {/* Social Media Icons */}
//       {/* <div className="row text-center mt-4">
//         <div className="col">
//           <div className="social-icons">
//             <a
//               href="https://facebook.com"
//               target="_blank"
//               rel="noopener noreferrer"
//             >
//               <FaFacebook className="text-primary fs-4 mx-2" />
//             </a>
//             <a
//               href="https://twitter.com"
//               target="_blank"
//               rel="noopener noreferrer"
//             >
//               <FaTwitter className="text-info fs-4 mx-2" />
//             </a>
//             <a
//               href="https://instagram.com"
//               target="_blank"
//               rel="noopener noreferrer"
//             >
//               <FaInstagram className="text-danger fs-4 mx-2" />
//             </a>
//             <a
//               href="https://linkedin.com"
//               target="_blank"
//               rel="noopener noreferrer"
//             >
//               <FaYoutube className="text-primary fs-4 mx-2" />
//             </a>
//           </div>
//         </div>
//       </div> */}

//       <div class="container mt-4">
//         <div class="row g-2">
//           <div class="col-md-4">
//             <div class="card shadow">
//               <div class="">
//                 <p
//                   style={{ backgroundColor: "#2376ad" }}
//                   className="p-2 text-center text-white rounded-2 fw-bold text-uppercase"
//                 >
//                   Twitter
//                 </p>
//                 <Timeline
//                   dataSource={{ sourceType: "profile", screenName: "my_nmc" }}
//                   options={{
//                     height: "310",
//                     // width: "350",
//                   }}
//                 />
//               </div>
//             </div>
//           </div>
//           <div class="col-md-4">
//             <div class="card shadow">
//               <div class="border bg-light">
//                 <p
//                   style={{ backgroundColor: "#2376ad" }}
//                   className="p-2 text-center text-white rounded-2 fw-bold text-uppercase"
//                 >
//                   Youtube
//                 </p>
//                 <iframe
//                   // width="320"
//                   height="310"
//                   src="https://www.youtube.com/embed/v6wykdtMdlg"
//                   title='#KnowYourNmc । "चला जाणून घेऊया नाशिक मनपाला!" । #mynmc #mynashik'
//                   frameborder="0"
//                   allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
//                   allowfullscreen
//                 ></iframe>
//               </div>
//             </div>
//           </div>
//           <div class="col-12 col-md-4">
//             <div className="card shadow">
//               <p
//                 style={{ backgroundColor: "#2376ad" }}
//                 className="p-2 text-center text-white rounded-2 fw-bold text-uppercase"
//               >
//                 Facebook
//               </p>
//               <iframe
//                 src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fmynashikmc%2F&tabs=timeline&width=340&height=499&small_header=true&adapt_container_width=true&hide_cover=true&show_facepile=true&appId"
//                 height="310"
//                 width="320"
//                 style={{ border: "none", overflow: "hidden" }}
//                 scrolling="no"
//                 frameborder="0"
//                 allowfullscreen="true"
//                 allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
//               ></iframe>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Contact;

import React from "react";
import {
  FaFacebook,
  FaTwitter,
  FaInstagram,
  FaLinkedin,
  FaYoutube,
} from "react-icons/fa";
import { Timeline } from "react-twitter-widgets";
import "./contact.css";

const Contact = () => {
  return (
    <div className="container contact-container">
      <div className="row text-center mb-5">
        {/* Office */}
        <div className="col-lg-4 col-md-6  py-2">
          <div className="contact-card shadow">
            <i className="bi bi-geo-alt-fill icon-primary fs-2"></i>
            <h5 className="card-title mt-3">OUR MAIN OFFICE</h5>
            <p className="card-text">
              Nashik Municipal Corporation Rajiv Gandhi Bhavan, Sharanpur Road,
              Nashik
            </p>
          </div>
        </div>

        {/* Phone */}
        <div className="col-lg-4 col-md-6  py-2">
          <div className="contact-card shadow">
            <i className="bi bi-telephone-fill icon-primary fs-2"></i>
            <h5 className="card-title mt-3">PHONE NUMBER</h5>
            <p className="card-text">+91-7030300300</p>
          </div>
        </div>

        {/* Email */}
        <div className="col-lg-4 col-md-6  py-2">
          <div className="contact-card shadow">
            <i className="bi bi-envelope-fill icon-primary fs-2"></i>
            <h5 className="card-title mt-3">EMAIL</h5>
            <p className="card-text">godavarinmc@gmail.com</p>
          </div>
        </div>
      </div>

      {/* Social Media Section */}
      <div className="row text-center">
        <h2 className="col">Follow us on</h2>
      </div>

      {/* Embedded Content Section */}
      <div className="container ">
        <div className="row g-2">
          {/* Twitter Embed */}
          <div className="col-md-4">
            <div className="embed-card shadow">
              <p className="p-2 text-center text-white bg-dark rounded-2 fw-bold text-uppercase">
                Twitter
              </p>
              <Timeline
                dataSource={{ sourceType: "profile", screenName: "my_nmc" }}
                options={{ height: "310" }}
              />
            </div>
          </div>

          {/* Youtube Embed */}
          <div className="col-md-4">
            <div className="embed-card shadow">
              <p className="p-2 text-center text-white bg-danger rounded-2 fw-bold text-uppercase">
                Youtube
              </p>
              <iframe
                height="310"
                src="https://www.youtube.com/embed/v6wykdtMdlg"
                title="#KnowYourNmc"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>

          {/* Facebook Embed */}
          <div className="col-md-4">
            <div className="embed-card shadow">
              <p className="p-2 text-center text-white bg-primary rounded-2 fw-bold text-uppercase">
                Facebook
              </p>
              <iframe
                src="https://www.facebook.com/plugins/page.php?href=https://www.facebook.com/mynashikmc/&tabs=timeline&width=340&height=499&small_header=true&adapt_container_width=true&hide_cover=true&show_facepile=true&appId"
                height="310"
                width="320"
                style={{ border: "none", overflow: "hidden" }}
                scrolling="no"
                frameborder="0"
                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
