import store from "."
import {

    logoutSuperAdmin,
    logoutdiviofficeUser,

} from "./actions"
import { useSelector } from "react-redux"
import { toast } from "react-toastify"

export function updateDashboardState(role, data) {
    switch (role) {
        // case "Student":
        //     store.dispatch(setStudentDashboard(data));
        //     break;
        default:
            break;
    }
}

export function useSelectAccess(role) {
    return useSelector((store) => {
        switch (role) {
            case "Super Admin":
                return store.superadmin
            case "Division Officer":
                return store.divisionofficer

        }
    })
}

export function logoutAccess(role) {
    switch (role) {
        case "Super Admin":
            store.dispatch(logoutSuperAdmin());
            break;
        case "Division Officer":
            store.dispatch(logoutdiviofficeUser());
            break;
        default:
            return;
    }
    toast.info("Logout successful", { toastId: "LogoutReplacable" });
} 
