import React from "react";
import AddSlider from "../../Administration/AddSlider";

const SuperAdminAddSlider = () => {
  return (
    <div>
      <AddSlider />
    </div>
  );
};

export default SuperAdminAddSlider;
