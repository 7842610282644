import React from "react";
import { AiFillMail, AiFillPhone } from "react-icons/ai";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="container text-center mt-3">
        <h4>{t("Organized By")}</h4>
        <h4 className="mb-3">
          {t("Nashik Municipal Corporation and Trimbak Municipal Council")}
        </h4>
        <div
          className="row text-center justify-content-center"
          style={{
            display: "flex",
            flexWrap: "wrap", // Allow wrapping on smaller screens
            gap: "10px",
          }}
        >
          <div className="col-auto">
            <img
              src="/logo2.png"
              className="my-2 img-fluid img-thumbnail"
              style={{
                width: "150px",
                height: "150px",
                objectFit: "contain",
                padding: "10px",
              }}
              alt="Logo 1"
            />
          </div>
          <div className="col-auto">
            <img
              src="/trimbak.png"
              className="my-2 img-fluid img-thumbnail"
              style={{
                width: "150px",
                height: "150px",
                objectFit: "contain",
                padding: "10px",
              }}
              alt="Logo 2"
            />
          </div>
          <div className="col-auto">
            <img
              src="/Life.png"
              className="my-2 img-fluid img-thumbnail"
              style={{
                width: "150px",
                height: "150px",
                objectFit: "contain",
                padding: "10px",
              }}
              alt="Logo 3"
            />
          </div>
          <div className="col-auto">
            <img
              src="/75.png"
              className="my-2 img-fluid img-thumbnail"
              style={{
                width: "150px",
                height: "150px",
                objectFit: "contain",
                padding: "10px",
              }}
              alt="Logo 4"
            />
          </div>
          <div className="col-auto">
            <img
              src="/swachbharat.png"
              className="my-2 img-fluid img-thumbnail"
              style={{
                width: "150px",
                height: "150px",
                objectFit: "contain",
                padding: "10px",
              }}
              alt="Logo 5"
            />
          </div>
          <div className="col-auto">
            <img
              src="/swach.png"
              className="my-2 img-fluid img-thumbnail"
              style={{
                width: "150px",
                height: "150px",
                objectFit: "contain",
                padding: "10px",
              }}
              alt="Logo 6"
            />
          </div>
        </div>
      </div>

      <div style={{ backgroundColor: "#e18d20" }}>
        <div className="p-3">
          <div className="row text-white">
            <div className="col-md-6">
              <h6 className="text-uppercase">{t("Contact Us")}</h6>
              <p>
                <AiFillMail /> godavarinmc@gmail.com
              </p>
              <p>
                <AiFillPhone /> +91-7030300300
              </p>
              <p>
                All rights are reserved with Nashik Municipal Corporation,
                Nashik
              </p>
            </div>
            <div className="col-md-6 text-md-end">
              <h6 className="text-uppercase">{t("Subscribe For Updates")}</h6>
              <div className="social-media">
                <ul>
                  <li>
                    <a href="https://www.facebook.com/mynashikmc/">
                      <i className="fab fa-facebook-f text-white"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/my_nmc/">
                      <i className="fab fa-instagram text-white"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://x.com/my_nmc">
                      <i className="fab fa-twitter text-white"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.youtube.com/c/mynmc">
                      <i className="fab fa-youtube text-white"></i>
                    </a>
                  </li>
                </ul>
              </div>
              <p>Last updated on 02.10.2024</p>
            </div>
          </div>
        </div>
      </div>

      {/* Media Queries to handle responsiveness */}
      <style jsx>{`
        @media (max-width: 768px) {
          .row.text-center.justify-content-center {
            flex-direction: column; // Stack the logos vertically on mobile
          }
          img {
            width: 120px;
            height: 120px;
          }
        }
        @media (max-width: 576px) {
          img {
            width: 100px;
            height: 100px;
          }
        }
      `}</style>
    </div>
  );
};

export default Footer;
