import React from "react";
import Dashboard from "../../Administration/Dashboard";

const SuperAdminDashboard = () => {
  return (
    <div>
      <Dashboard />
    </div>
  );
};

export default SuperAdminDashboard;
